import { ImpactLoopLogo } from '@/components/Icons/ImpactLoopLogo';
import { Box, Flex, Link, Text } from '@chakra-ui/react';

const NotFoundPage = () => {
  return (
    <Flex
      height={'100%'}
      justify='center'
      alignItems={'center'}
      flexDir={'column'}
    >
      <Box mb='md'>
        <ImpactLoopLogo width='10rem' height='10rem' variant='symbolGreen' />
      </Box>
      <Text mb='md'>The page you are looking for does not exist.</Text>
      <Text>
        Please check the URL and try again or go back to the{' '}
        <Link href='/'>homepage.</Link>
      </Text>
      <Text>
        If you think this is an error, please contact support at{' '}
        <Link href='mailto:support@impact-loop.com'>
          support@impact-loop.com
        </Link>
      </Text>
    </Flex>
  );
};

export default NotFoundPage;
